import React from 'react';
import HomeHorizontalSlider from './home-horizontal-slider';
import "../styles/find-out-about-ihf-reviews-slider.css"
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const opinionsContent = [
  {
    description: "home.opinion-1.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Kamila Brylak.jpeg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-1.author",
    position: "home.opinion-1.position",
  },
  {
    description: "home.opinion-2.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/karolina-tkaczuk.jpg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-2.author",
    position: "home.opinion-2.position",
  },
  {
    description: "home.opinion-3.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Marta Zalewska.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-3.author",
    position: "home.opinion-3.position",
  },
  {
    description: "home.opinion-4-1.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Cezary Skarżyński_pfp_photo.jpg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-4-1.author",
    position: "home.opinion-4-1.position",
  },
  {
    description: "home.opinion-4.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Natalia Szczucka.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-4.author",
    position: "home.opinion-4.position",
  },
  {
    description: "home.opinion-5.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Klemens-Gąsienica-Ciułacz-photo-1.jpg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-5.author",
    position: "home.opinion-5.position",
  },
  {
    description: "home.opinion-6.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Piotr Lazarek.jpg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-6.author",
    position: "home.opinion-6.position",
  },
  {
    description: "home.opinion-7.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Oliwia Myczkowska z Dziadkiem.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-7.author",
    position: "home.opinion-7.position",
  },
  {
    description: "home.opinion-8.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Thomas Werner.jpg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-8.author",
    position: "home.opinion-8.position",
  },
  {
    description: "home.opinion-9.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Filip Budny.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-9.author",
    position: "home.opinion-9.position",
  },
  {
    description: "home.opinion-10.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Maciej Łach.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-10.author",
    position: "home.opinion-10.position",
  },
  {
    description: "home.opinion-11.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Michał M. Czyżewski-photo.jpeg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-11.author",
    position: "home.opinion-11.position",
  },
  {
    description: "home.opinion-11-1.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Monika Borowiecka.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-11-1.author",
    position: "home.opinion-11-1.position",
  },
  {
    description: "home.opinion-12.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Jakub Kozioł.jpeg"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-12.author",
    position: "home.opinion-12.position",
  },
  {
    description: "home.opinion-13.description",
    img: (
      <StaticImage
        className="ihf-review-user-img"
        src="../../../images/home/opinions/Dominika Duda.png"
        alt=""
        placeholder="none"
        loading="lazy"
      />
    ),
    author: "home.opinion-13.author",
    position: "home.opinion-13.position",
  },
]

const getOpinionsContent = (t) => {
  const opinions = [];
  for (let item of opinionsContent) {

    let descriptionStyle = {}
    const descriptionLength = t(item.description).length;
    if (descriptionLength > 500) {
      descriptionStyle = {fontSize: "14px"}
    } else if (descriptionLength > 400) {
      descriptionStyle = {fontSize: "16px"}
    }

    opinions.push(
      <div className='find-out-about-ihf-reviews-slider'>
        <p style={descriptionStyle}>{t(item.description)}</p>
        <div className='find-out-about-ihf-reviews-slider-person-info'>
          {item.img}
          <div className='find-out-about-ihf-reviews-slider-person-additional-info'>
            <p className='find-out-about-ihf-reviews-slider-person-name'>{t(item.author)}</p>
            <p className='find-out-about-ihf-reviews-slider-person-role'>{t(item.position)}</p>
          </div>
        </div>
      </div>
    );
  }
  return opinions;
}

const Opinions = () => {
  const {t} = useTranslation();
  return (
    <div className="find-out-about-ihf-reviews-slider-page">
      <div className="two-circles-motive1">
        <StaticImage
          src="../../../images/home/theme-circles/two-circles-pt1.png"
          alt="two-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <div className="two-circles-motive2">
        <StaticImage
          src="../../../images/home/theme-circles/two-circles-pt2.png"
          alt="two-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <section className="container">
        <div className="find-out-about-ihf-reviews-text">
          <h3>{t`home.find-out-about-IHF-reviews-slider-subtitle`}</h3>
          <h2>{t`home.find-out-about-IHF-reviews-slider-title`}</h2>
        </div>
        <HomeHorizontalSlider slides={getOpinionsContent(t)} totalSlidesStateProp={getOpinionsContent(t).length} />
      </section>
    </div>
  )
    ;
};

export default Opinions;