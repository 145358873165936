import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import Slider from 'react-slick';
import "../styles/home-horizontal-slider.css"

const HomeHorizontalSlider = ({slides, isSliderCellSmall = false, totalSlidesStateProp = 8, isSliderAutoplay = false, speed=1000, autoplaySpeed=3000}) => {
  const { t } = useTranslation();
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M19 12H5"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 18L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 6L19 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <svg
        className={className}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#FFFFFF">
        <path
          d="M5 12H19"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 6L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 18L5 12"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  const [totalSlidesState, setTotalSlidesState] = useState(totalSlidesStateProp);
  const totalSlides = totalSlidesState;
  const slidesToShow = 3;

  const settings = {
    dots: true,
    infinite: true,
    speed: speed,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: isSliderAutoplay,
    autoplaySpeed: autoplaySpeed,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    },
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 976,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const totalPages = Math.ceil(totalSlides / slidesToShow);
  const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <>
      <div className="home-horizontal-slider-ctn">
        <div>
          <Slider className="home-horizontal-slider" {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className={isSliderCellSmall ? "home-horizontal-slide-small" : "home-horizontal-slide"}>
                {slide}
              </div>
            ))}
          </Slider>
          <div className="slider-pagination">
            {currentPage}/{totalPages}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHorizontalSlider;
