import React from 'react';
import "../styles/we-promote-polish-startups.css"
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Arrow = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86967 8.54395L5.21342 7.89622L7.91513 5.19451H0.917969V4.25701H7.91513L5.21342 1.56383L5.86967 0.907582L9.68786 4.72576L5.86967 8.54395Z"
        fill="#5FA5C5" />
    </svg>
  )
}

const WePromotePolishStartupsSection = () => {
  const {t} = useTranslation();
  return (
    <div className="we-promote-polish-startups-section">
      <section className="container">
        <div className="position-relative">
          <div className="we-promote-polish-startups-wrapper">
            <div className="we-promote-polish-startups-texts">
              <h3>{t`home.we-promote-polish-startups-section-subtitle`}</h3>
              <h2>{t`home.we-promote-polish-startups-section-title`}</h2>
              <p>{t`home.we-promote-polish-startups-section-text`}</p>
            </div>
            <div className="we-promote-polish-startups-links-wrapper">
              <div className="we-promote-polish-startups-cell-wrapper">
                <StaticImage
                  src="../../../images/home/astronaut-small.png"
                  alt="astronaut small"
                  loading="lazy"
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
                <div className="we-promote-polish-startups-cell-text-wrapper">
                  <p>{t`home.we-promote-polish-startups-section-cell-1-text`}</p>
                  <a
                    href="https://youtu.be/gSLUTJtAiyk"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="we-promote-polish-startups-cell-text-link"
                  >
                    {t`home.we-promote-polish-startups-section-cell-1-button`}
                    <Arrow />
                  </a>
                </div>
              </div>
              <div className="we-promote-polish-startups-cell-wrapper-disabled">
                <StaticImage
                  src="../../../images/home/astronaut-glitch.png"
                  alt="astronaut glitch"
                  loading="lazy"
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
                <div className="we-promote-polish-startups-cell-text-disabled-wrapper">
                  <p>{t`home.we-promote-polish-startups-section-cell-2-text`}</p>
                  <p
                    className="we-promote-polish-startups-cell-disabled-link-text">{t`home.we-promote-polish-startups-section-cell-2-button`}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="team-polaroid-photos-wrapper">
            <StaticImage
              src="../../../images/home/team-polaroid-photos5.png"
              alt="team polaroid photos"
              loading="lazy"
              className="team-polaroid-photo"
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default WePromotePolishStartupsSection;