import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/home-landing-header-style.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Video from '../../../components/video';
import Modal from 'react-modal';
import OurPartnersSlider from './our-partners-slider';

const HomeLandingHeader = () => {
  const {t} = useTranslation();
  const [isModal, setIsModal] = React.useState(false);

  return (
    <>
      <StaticImage
        className="circle-motive-left"
        src="../../../images/home/theme-circles/circle-elements-left.png"
        alt="Circle motive left"
        placeholder="none"
        loading="lazy"
      />

      <div className="intro-ctn">
        <div className="container">
          <div className="intro-header-ctn">
            <h1>{t`home.about-title`}</h1>
            <p>{t`home.about-paragraph`}</p>
          </div>

          <button className="intro-image-ctn" onClick={() => setIsModal(true)}>
            <div className="video-background yt-video">
              <div className="video-wrapper">
                <iframe
                  width='100%'
                  src="https://www.youtube.com/embed/gSLUTJtAiyk?si=kYJOpCMuV1Lk1RtJ&autoplay=1&mute=1&controls=0&loop=1&playlist=gSLUTJtAiyk&modestbranding=1&showinfo=0&rel=0&playsinline=1&enablejsapi=1&iv_load_policy=3"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
              </div>
            </div>
            <div className="hero-gradient "/>

          </button>
        </div>
        <Modal
          isOpen={isModal}
          style={boxStyles}
          onRequestClose={() => setIsModal(false)}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          preventScroll={true}
          className="video-modal-content_ctn"
        >
          <button style={{height: '100%', width: '100%', border: 'none', background: 'transparent'}} onClick={() => setIsModal(false)} aria-label="Close video"/>
          <Video
            src="https://www.youtube.com/embed/gSLUTJtAiyk?si=kYJOpCMuV1Lk1RtJ&autoplay=1&playlist=gSLUTJtAiyk&modestbranding=1&showinfo=0&rel=0&playsinline=1&enablejsapi=1&iv_load_policy=3"
            title="New generation of Founders"
          />
          <button aria-label="Close video" style={{height: '100%', width: '100%', border: 'none', background: 'transparent'}} onClick={() => setIsModal(false)}/>
        </Modal>
      </div>
      <OurPartnersSlider/>
    </>
  );
}

const boxStyles = {
  overlay: {
    zIndex: 103,
    background: 'rgba(0,0,0,0.6)',
  },
};

export default HomeLandingHeader;
