import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import "../styles/our-partners-slider.css"
import { HomeSecondaryBorderBtn } from './home-buttons';

const OurPartnersSlider = () => {
  const {t} = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 974,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className="container">
      <div className="we-work-with-ctn">
        <h3>{t`home.our-partners-slider-title`}</h3>
        <Slider {...settings}>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.agh.edu.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/z2.svg"
                alt="Logo AGH"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.astrazeneca.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/Astra.png"
                alt="Logo Astra Zeneca"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.ergohestia.pl/ubezpieczenie-podrozy/?gad_source=1&gclid=CjwKCAiAh6y9BhBREiwApBLHC0XDVdMbY7ZMcOuXlE8d0C6A9ANxJbxvQ0p1GDeQs-EW6nF3quSYNhoC7EkQAvD_BwE" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/ergo.png"
                alt="Logo Ergo Hestia"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.gpw.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/gpw.png"
                alt="Logo GPW"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://inpost.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/inpost.png"
                alt="Logo InPost"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://lukasiewicz.gov.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/Lukasiewicz.png"
                alt="Logo sieć badawcza Łukasiewicz"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.gov.pl/web/ncbr" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/ncbir.png"
                alt="Logo NCBR"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.parp.gov.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/parp.png"
                alt="Logo PARP"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.pw.edu.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/z8.svg"
                alt="Logo PW"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.sgh.waw.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/z1.svg"
                alt="Logo SGH"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://www.uw.edu.pl/" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/z5.svg"
                alt="Logo UW"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="we-work-with-a-wrapper">
            <a href="https://uprp.gov.pl/pl" target="_blank" rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/home/we-work-with-logos/uprp.png"
                alt="Logo Urzędu Patentowego Rzeczypospolitej Polskiej"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
        </Slider>
        <div className="join-the-group-of-partners-button">
          <HomeSecondaryBorderBtn to="/partners">{t`home.join-the-group-of-partners-button`}</HomeSecondaryBorderBtn>
        </div>
      </div>
    </section>
  );
}

export default OurPartnersSlider;
