import React from 'react';
import "../styles/we-help-fight-barriers.css"
import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
const Arrow = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86967 8.54395L5.21342 7.89622L7.91513 5.19451H0.917969V4.25701H7.91513L5.21342 1.56383L5.86967 0.907582L9.68786 4.72576L5.86967 8.54395Z"
        fill="#5FA5C5" />
    </svg>
  )
}
const DisabledArrow = () => {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86967 8.54395L5.21342 7.89622L7.91513 5.19451H0.917969V4.25701H7.91513L5.21342 1.56383L5.86967 0.907582L9.68786 4.72576L5.86967 8.54395Z"
        fill="#8F8F8F" />
    </svg>
  )
}

const WeHelpFightBarriersSection = () => {
  const {t} = useTranslation();
  return (
    <div className="we-help-fight-barriers-section-wrapper">
      <div className="three-circles-motive">
        <StaticImage
          src="../../../images/home/theme-circles/3 circles pt2.png"
          alt="Three-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <div className="two-circles-motive">
        <StaticImage
          src="../../../images/home/theme-circles/2 circles.png"
          alt="Three-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <section className="container">
        <div className="we-help-fight-barriers-ctn">
          <h2>{t`home.we-help-fight-barriers-title`}</h2>
          <div className="we-help-fight-barriers-cells-wrapper">
            <div className="we-help-fight-barriers-cell">
              <div className="we-help-fight-barriers-short-cell-wrapper">
                <div className="we-help-fight-barriers-cell-short">
                  <div className="we-help-fight-barriers-cell-long-photo">
                    <StaticImage src="../../../images/home/Kacper-Raciborski-image-ver1.png" alt="Kacper-Raciborski-photo" />
                    <div className="we-help-fight-barriers-cell-long-photos-text-v1">
                      <p>Kacper Raciborski</p>
                      <div className="we-help-fight-barriers-cell-long-photos-text-1">
                        <p>{t`home.we-help-fight-barriers-position1`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="we-help-fight-barriers-cell-fight-short-text">
                    <p>{t`home.we-help-fight-barriers-cell-1-subtitle`}</p>
                    <h3>{t`home.we-help-fight-barriers-cell-1-title`}</h3>
                    <p className="we-help-fight-barriers-cell-text-body">{t`home.we-help-fight-barriers-cell-1-text`}</p>
                    <Link to="/team" className="we-help-fight-barriers-cells-text-link">{t`home.we-help-fight-barriers-cell-1-2-3-button`} <Arrow /></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="we-help-fight-barriers-short-cells-wrapper">
              <div className="we-help-fight-barriers-cell">
                <div className="we-help-fight-barriers-cell-long">
                  <div className="we-help-fight-barriers-cell-long-photos">
                    <StaticImage src="../../../images/home/Jakub-Dąbroś-image-ver1.png" alt="Jakub-Dąbroś-photo" />
                    <div className="we-help-fight-barriers-cell-long-photos-text-v2">
                      <p>Jakub Dąbroś</p>
                      <div className="we-help-fight-barriers-cell-long-photos-text-2">
                        <p><p>{t`home.we-help-fight-barriers-position2`}</p></p>
                      </div>
                    </div>
                  </div>
                  <div className="we-help-fight-barriers-cell-fight-text">
                    <h3>{t`home.we-help-fight-barriers-cell-2-title`}</h3>
                    <p className="we-help-fight-barriers-cell-text-body">{t`home.we-help-fight-barriers-cell-2-text`}</p>
                    <Link to="/documents" className="we-help-fight-barriers-cells-text-link">{t`home.we-help-fight-barriers-cell-1-2-3-button`} <Arrow /></Link>
                  </div>
                </div>
              </div>
              <div className="we-help-fight-barriers-cell">
                <div className="we-help-fight-barriers-cell-long">
                  <div className="we-help-fight-barriers-cell-long-photos">
                    <StaticImage src='../../../images/home/Books-image.png' alt='Book-photo' />
                  </div>
                  <div className="we-help-fight-barriers-cell-fight-text">
                    <h3>{t`home.we-help-fight-barriers-cell-3-title`}</h3>
                    <p className="we-help-fight-barriers-cell-text-body">{t`home.we-help-fight-barriers-cell-3-text`}</p>
                    <div className="we-help-fight-barriers-cells-text-link-disabled">
                      <p>{t`home.we-help-fight-barriers-cell-1-2-3-button`}  <DisabledArrow   /></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeHelpFightBarriersSection;