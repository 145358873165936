import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import "../styles/article-list.css"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Dot = () => {
  return (
    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99967 5.66634C4.47243 5.66634 5.66634 4.47243 5.66634 2.99967C5.66634 1.52692 4.47243 0.333008 2.99967 0.333008C1.52692 0.333008 0.333008 1.52692 0.333008 2.99967C0.333008 4.47243 1.52692 5.66634 2.99967 5.66634Z"
        fill="#4EBFEE" />
    </svg>
  )
}

const ArticlesList = () => {
  const data = useStaticQuery(graphql`
    query {
     news: allContentfulNews(sort: { order: DESC, fields: createdAt }, limit: 20) {
        edges {
          node {
            title
            type
            contentful_id
            createdAt(formatString: "DD MMMM YYYY")
            thumbnail {
              title
              file {
                url
              }
            }
            authors {
              title
            }
          }
        }
      }
    }
  `);
  const { t } = useTranslation();

  const uniqueNews = Array.from(new Map(data.news.edges.map(item => [item.node.contentful_id, item])).values()).slice(0, 3);
  return (
    <div className="article-list-ctn">
      <section className="container">
        <h3>{t`home.news-subtitle`}</h3>
        <h2>{t`home.news-title`}</h2>
        <div className="article-list-wrapper">
          {uniqueNews.map(({ node }) => (
            <div key={node.contentful_id} className="article-list-cell-wrapper">
              <Link to={`/news/${node.contentful_id}`}>
                {node.thumbnail && (
                  <div className="article-list-cell-img-wrapper">
                    <img
                      src={node.thumbnail.file.url}
                      alt={node.thumbnail.title}
                      className="article-list-cell-img"
                    />
                  </div>
                )}
                <div className="article-list-cell-data-wrapper">
                  <h4>{node.title}</h4>
                  <div className="article-list-cell-data-secondary-wrapper">
                    <p>{node.createdAt}</p>
                    <Dot />
                    <p>{node.authors[0].title}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ArticlesList;
