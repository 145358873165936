import React from 'react';
import "../styles/business-growth.css"
import videoSrc from '../../../images/home/business-growth.gif';
import "../../../images/home/outsorce-logo.png"
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
const BusinessGrowth = () => {
  const {t} = useTranslation();
  return (
    <div className="business-growth-section">
      <section className='container'>
        <div className="business-growth-section-ctn">
          <div className="business-growth-section-texts">
            <h3>{t`home.business-growth-subtitle`}</h3>
            <h2>{t`home.business-growth-title`}</h2>
            <p>{t`home.business-growth-text`}</p>
            <div className="business-growth-section-logos-wrapper">
              <div className="outsource-logo">
                <StaticImage
                  src="../../../images/home/outsource-logo5.png"
                  alt="outsorce logo"
                  placeholder="blurred"
                  loading="lazy"
                  className="outsource-logo2"
                />
              </div>
              <div className="business-growth-section-logos-button">
                <div>
                  {t`home.business-growth-button`}
                </div>
              </div>
            </div>
          </div>
          <img src={videoSrc} alt="Animacja biznesowa" className="responsive-image"/>
        </div>
      </section>
    </div>
  );
};

export default BusinessGrowth;