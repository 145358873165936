import React from 'react';
import HomeHorizontalSlider from './home-horizontal-slider';
import "../styles/what-they-write-about-us-in-press.css"
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const WhatTheyWriteAboutUsInPressSlider = () => {
  const {t} = useTranslation();
  const slidesContent = [
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-1`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/forbes-icon.png"
          alt="Forbs logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.forbes.pl/life/wydarzenia/nowa-generacja-zalozycieli/nrfvy7w " target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-2`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/mam-startup-icon.png"
          alt="Mam startup logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mamstartup.pl/nowa-generacja-zalozycieli-fundacja-innovations-hub-stworzyla-film-o-polskich-founderach-genz/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-3`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/my-company-polska-icon.png"
          alt="My Compnany Polska logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mycompanypolska.pl/artykul/nurt-innowacji-fundacja-innovations-hub-ksztaltuje-nowe-pokolenie-polskich-start-upow/12244" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-4`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/mam-startup-icon.png"
          alt="Mam Startup logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mamstartup.pl/playbookfoundera-mlodzi-innowatorzy-pokolenie-z-w-swiecie-startupow/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>
        {t`home.what-they-write-about-us-in-press-slider-text-5`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <div className="what-they-write-about-us-in-press-slider-logo-antal">
          <StaticImage
            src="../../../images/home/press-logos/antal.png"
            alt="Rynek Akceleracji w Polsce, Antal logo"
            placeholder="none"
            objectFit={"contain"}
            loading="lazy"
            objectFit={"contain"}
            className="company-logo-antal"
          />
        </div>
        <a href="https://antal.pl/wiedza/raport/rynek-akceleracji-w-polsce" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-6`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/medonet-icon.png"
          alt="Medonet logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.medonet.pl/zdrowie/wiadomosci,bakteriobojcza-zywica--sztuczna-inteligencja-i-jednorozec--od-inspiracji-do-startupu--innovations-hub-summit-za-nami,artykul,93699786.html?utm_source=www.linkedin.com_viasg_medonet&utm_medium=social&utm_campaign=leo_automatic&srcc=undefined&utm_v=2" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-7`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/rp-icon.png"
          alt="Rzeczpospolita logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://pro.rp.pl/abc-firmy/art40693401-pomysl-na-biobojcza-zywice-dla-szpitali-i-laboratoriow-najlepszym-startupem" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-8`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/forbes-icon.png"
          alt="Forbes Polska logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.forbes.pl/biznes/innovations-hub-w-oferowanym-przez-fundacje-programie-mentoringowym-wzielo-udzial/vf7knl6" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-9`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/rmffm-icon.png"
          alt="Radio RMF FM logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.rmf24.pl/regiony/lodz/news-biobarrier-z-politechniki-lodzkiej-zwycieza-innovations-hub,nId,7612241#crp_state=1" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-10`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/mam-startup-icon.png"
          alt="Mam Startup logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mamstartup.pl/innovations-hub-summit-2024-nowy-punkt-na-startupowej-mapie-polski/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-11`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/my-company-polska-icon.png"
          alt="My Compnay Polska logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mycompanypolska.pl/artykul/innovations-hub-summit-przyszlosc-polskiej-inkubacji-startupowej/15342" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-12`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/puls-biznesu-icon.png"
          alt="Puls Biznesu logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.pb.pl/innovations-hub-nowe-pokolenie-mlodych-na-polskiej-scenie-startupowej-1220320" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-13`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/brief.png"
          alt="brief logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://brief.pl/innovations-hub-summit-nowy-punkt-na-startupowej-mapie-polski/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-14`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/rp-icon.png"
          alt="Rzeczpospolita logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.rp.pl/prawo/art40907481-mlodzi-innowatorzy-nie-musza-wymyslac-kola-na-nowo" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-15`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/forbes-icon.png"
          alt="Forbes Polska logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://www.forbes.pl/opinie/fundacja-innovations-hub-wspiera-startupy-na-drodze-do-sukcesu/1gkffbg" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-16`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/mam-startup-icon.png"
          alt="Mam Startup logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mamstartup.pl/nowa-generacja-zalozycieli-fundacja-innovations-hub-stworzyla-film-o-polskich-founderach-genz/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-17`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/mam-startup-icon.png"
          alt="Mam Startup logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://mamstartup.pl/fundacja-innovations-hub-tworzy-od-podstaw-ekosystem-startupow-mlodych-ludzi/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-18`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/liderzy-innowacyjnosci-icon.png"
          alt="Liderzy Innowacyjności logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://liderzyinnowacyjnosci.com/fundacja-innovations-hub-liderem-inkubacji-startupow-w-polsce/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  <div className="what-they-write-about-us-in-press-slider-group">
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-19`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/bussines-insider-icon.png"
          alt="Business Insider-logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://businessinsider.com.pl/lifestyle/dolacz-do-drugiej-edycji-medbiz-innovations/b44ltq0" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
    <div className="what-they-write-about-us-in-press-slider">
      <p>{t`home.what-they-write-about-us-in-press-slider-text-20`}</p>
      <div className="what-they-write-about-us-in-press-slider-logo">
        <StaticImage
          src="../../../images/home/press-logos/perspektywy-icon.png"
          alt="Perspektywy logo"
          placeholder="none"
          loading="lazy"
          objectFit={"contain"}
          className="company-logo"
        />
        <a href="https://perspektywy.pl/portal/index.php?option=com_content&view=article&id=5688:medbiz-innovations&catid=24&Itemid=119" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
      </div>
    </div>
  </div>,
  ];

  return (
    <div className="what-they-write-about-us-in-press-page">
      <div className="three-circles-motive">
        <StaticImage
          src="../../../images/home/theme-circles/three-circles.png"
          alt="Three circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <section className="container">
        <div className="two-circles-motive">
          <StaticImage
            src="../../../images/home/theme-circles/two-circles-pt3.png"
            alt="Two circles "
            placeholder="none"
            loading="eager"
          />
        </div>
        <div className="what-they-write-about-us-in-press-ctn">
          <div className="what-they-write-about-us-in-press-titles">
            <h3>{t`home.what-they-write-about-us-in-press-subtitle`}</h3>
            <h2>{t`home.what-they-write-about-us-in-press-title`}</h2>
          </div>
          <div className="what-they-write-about-us-in-press-body">
            <div className="home-horizontal-slider-ctn">
              <HomeHorizontalSlider isSliderCellSmall={true} slides={slidesContent} totalSlidesStateProp={10}/>
            </div>
            <div className="what-they-write-about-us-in-press-body-newspaper">
              <StaticImage alt="newspaper" src="../../../images/home/newspaper3.png" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatTheyWriteAboutUsInPressSlider;