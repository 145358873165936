import React from 'react';
import "../styles/discover-our-activities.css"
import "../../../images/home/outsorce-logo.png"
import { HomeBorderBtn } from './home-buttons';
import { WhiteButton } from '../../documents-page/styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const DiscoverOurActivitiesSection = () => {
  const {t} = useTranslation();
  return (
    <div className="discover-our-activities-section">
      <section className='container'>
        <div className="discover-our-activities-wrapper">
          <div className="discover-our-activities-bg-img1">
            <StaticImage
              src="../../../images/home/Innovations-Academy1-1-1.png.png"
              alt="discover our activities bg-img1"
              loading="lazy"
            />
          </div>
          <div className="discover-our-activities-bg-img2">
            <StaticImage
              src="../../../images/home/Innovations-Academy1-2-11.png"
              alt="discover our activities-bg-img2"
              loading="lazy"
            />
          </div>
          <div className="discover-our-activities-text-wrapper">
            <h3>{t`home.discover-our-activities-title`}</h3>
            <h2>{t`home.discover-our-activities-main-title`}</h2>
            <p>{t`home.discover-our-activities-main-text`}</p>
            <div className="discover-our-activities-text-buttons">
              <WhiteButton as={Link} to="/registration">
                {t`home.discover-our-activities-button-1`}
              </WhiteButton>
              <div className="discover-our-activities-button">
                <HomeBorderBtn to="/program-inkubacyjny">
                  {t`home.discover-our-activities-button-2`}
                </HomeBorderBtn>
              </div>
            </div>
          </div>
          <div className="discover-our-activities-cells-ctn">
            <h4>{t`home.discover-our-activities-cells-title`}</h4>
            <div className="discover-our-activities-cells-wrapper">
              <Link className="discover-our-activities-cell" to="/mpk/">
                <StaticImage
                  src="../../../images/home/Logos/Innovations-Academy-icon1.png"
                  alt="astronaut glitch"
                  loading="lazy"
                  objectFit={"contain"}
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
              </Link>
              <Link className="discover-our-activities-cell" to="/fsts/">
                <StaticImage
                  src="../../../images/home/Logos/Innovations-Academy-icon2.png"
                  alt="astronaut glitch"
                  loading="lazy"
                  objectFit={"contain"}
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
              </Link>
              <Link className="discover-our-activities-cell"
                 to="/akademia-mlodego-innowatora/">
                <StaticImage
                  src="../../../images/home/Logos/Akademia-Młodego-Innowatora-icon1.png"
                  alt="astronaut glitch"
                  loading="lazy"
                  objectFit={"contain"}
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
              </Link>
              <Link className="discover-our-activities-cell" to="/future-academy/">
                <StaticImage
                  src="../../../images/home/Logos/Akademia-Przyszłości-icon1.png"
                  alt="astronaut glitch"
                  loading="lazy"
                  objectFit={"contain"}
                  className="we-promote-polish-startups-cell-astronaut-img"
                />
              </Link>
              <Link className="discover-our-activities-cell" to="/biz-on/">
                <StaticImage
                  src="../../../images/home/Logos/BizOn-icon1.png"
                  alt="BizOn image"
                  loading="lazy"
                  objectFit={"contain"}
                  className="we-promote-polish-startups-cell-img"
                />
              </Link>
              <Link className="discover-our-activities-cell" to="/program-inkubacyjny/">
                  <StaticImage
                    src="../../../images/home/Logos/Program_inkubacyjny_jasny.svg"
                    alt="Program inkubacyjny logo"
                    loading="lazy"
                    objectFit={"contain"}
                    className="we-promote-polish-startups-cell-img"
                  />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DiscoverOurActivitiesSection;