import './home.css';

import HomeLandingHeader from './components/home-landing-header';
import InteractWithUs from './components/interact-with-us';
import Layout from '../../components/layout/layout';
import React from 'react';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Opinions from './components/opinions';
import LookingForStartupsInPolandSlider from './components/looking-for-startups-in-poland-slider';
import WhatTheyWriteAboutUsInPressSlider from './components/what-they-write-about-us-in-press';
import WeAreCreatingCommunityOfFounders from './components/we-are-creating-community-of-founders';
import BusinessGrowth from './components/business-growth';
import WePromotePolishStartupsSection from './components/we-promote-polish-startups';
import WeHelpFightBarriersSection from './components/we-help-fight-barriers';
import DiscoverOurActivitiesSection from './components/discover-our-activities';
import ArticleList from './components/article-list';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Layout withHeader={false} darkTheme={true}>
      <Seo
        title={t`home.page-title`}
        description={t`home.page-description`}
        ogImage={ogImage}
      />
      <div className="home-ctn">
        <HomeLandingHeader />
        <DiscoverOurActivitiesSection />
        <WeAreCreatingCommunityOfFounders />
        <LookingForStartupsInPolandSlider />
        <WeHelpFightBarriersSection/>
        <WePromotePolishStartupsSection />
        <BusinessGrowth />
        <Opinions />
        <WhatTheyWriteAboutUsInPressSlider />
        <ArticleList />
        <InteractWithUs />
      </div>
    </Layout>
  );
};

export default HomePage;
