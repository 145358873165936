import React from 'react';
import HomeHorizontalSlider from './home-horizontal-slider';
import "../styles/looking-for-startups-in-poland-slider.css"
import { StaticImage } from 'gatsby-plugin-image';
import { WhiteButton } from '../../documents-page/styled.components';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
const LookingForStartupsInPolandSlider = () => {
  const {t} = useTranslation();

const slidesContent = [
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Bee Healthy Honey image" className="looking-for-startups-in-poland-slider-header-img-bhh" src="../../../images/home/startups-logos/bee-healthy-honey.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Bee Healthy Honey</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-1`}</h5>
      <a href="https://varroastopper.com/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="play.air image" className="looking-for-startups-in-poland-slider-header-img" src="../../../images/home/startups-logos/play-air.jpeg" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>play.air</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-2`}</h5>
      <a href="https://logoplan.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Calmsie image" className="looking-for-startups-in-poland-slider-header-img" src="../../../images/home/startups-logos/Calmsie.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Calmsie</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-3`}</h5>
      <a href="https://www.calmsie.ai/" target="_blank" rel="noreferrer nofollow" >{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Binderless image" className="looking-for-startups-in-poland-slider-header-img" src="../../../images/home/startups-logos/Binderless.jpg" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Binderless</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-4`}</h5>
      <a href="https://www.binderless.com/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Envirly image" className="looking-for-startups-in-poland-slider-header-img-envirly"
                   src="../../../images/home/startups-logos/Envirly.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Envirly</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-5`}</h5>
      <a href="https://www.envirly.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="CleverClaim image" className="looking-for-startups-in-poland-slider-header-img-cleverClaim" src="../../../images/home/startups-logos/CleverClaim.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>CleverClaim</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-6`}</h5>
      <a href="https://cleverclaim.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Staffly image" className="looking-for-startups-in-poland-slider-header-img-staffly" src="../../../images/home/startups-logos/Staffly.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Staffly</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-7`}</h5>
      <a href="https://staffly.pl/?utm_term=staffly&utm_source=google&utm_medium=cpc&utm_campaign=%5Bsearch%5D_-_brand_-_dop._%C5%9Bcis%C5%82e&utm_adgroup=grupa_reklam_1&utm_placement&gad_source=1&gclid=CjwKCAiAh6y9BhBREiwApBLHC03ZsHL8-XDC4Vp_G25iKg8Ot3VHOt0QfjT-PN9kZBtvYR_VYJ6YrhoCyCcQAvD_BwE" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Znajdź Zastępstwo image" className="looking-for-startups-in-poland-slider-header-img-znajdz-zastępstwo" src="../../../images/home/startups-logos/Znajdź Zastepstwo.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Znajdź Zastępstwo</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-8`}</h5>
      <a href="https://www.znajdzzastepstwo.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Codejet image" className="looking-for-startups-in-poland-slider-header-img" src="../../../images/home/startups-logos/Codejet.jpg" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Codejet</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-9`}</h5>
      <a href="https://codejet.ai/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Modue image" className="looking-for-startups-in-poland-slider-header-img-modue" src="../../../images/home/startups-logos/modue.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Modue</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-10`}</h5>
      <a href="https://modue.com/?srsltid=AfmBOooFvD-ib5XMp4gWgz2tqHUUxqiO-8inQJibIBy2Y6TEzIk2S-t_" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="PicMe image" className="looking-for-startups-in-poland-slider-header-img-pick-me" src="../../../images/home/startups-logos/PicMe.svg" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>PicMe</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-11`}</h5>
      <a href="https://picme.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="ElevenLabs image" className="looking-for-startups-in-poland-slider-header-img-eleven" src="../../../images/home/startups-logos/Eleven Labs.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>ElevenLabs</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-12`}</h5>
      <a href="https://elevenlabs.io/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="VitVio image" className="looking-for-startups-in-poland-slider-header-img-vitvio" src="../../../images/home/startups-logos/VitVio.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>VitVio</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-13`}</h5>
      <a href="https://www.vitvio.com/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Wayde image" className="looking-for-startups-in-poland-slider-header-img-vitvio" src="../../../images/home/startups-logos/Wayde.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Wayde</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-14`}</h5>
      <a href="https://www.linkedin.com/company/wayde-io/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Uhura Bionics image" className="looking-for-startups-in-poland-slider-header-img-uhura" src="../../../images/home/startups-logos/Uhura bionics.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Uhura Bionics</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-15`}</h5>
      <a href="https://www.uhura.pl/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="Therapy Guide image" className="looking-for-startups-in-poland-slider-header-img-therapy-guide" src="../../../images/home/startups-logos/Therapy Guide.png" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>Therapy Guide</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-16`}</h5>
      <a href="https://www.therapyguide.eu/" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
  <div className="looking-for-startups-in-poland-slider">
    <div className="looking-for-startups-in-poland-slider-header-img-bg">
      <StaticImage alt="NIRBY image" className="looking-for-startups-in-poland-slider-header-img" src="../../../images/home/startups-logos/Nirby.jpeg" />
    </div>
    <div className="looking-for-startups-in-poland-slider-info">
      <h4>NIRBY</h4>
      <h5>{t`home.looking-for-startups-in-poland-slider-text-17`}</h5>
      <a href="https://www.nirby-solutions.com/pl" target="_blank" rel="noreferrer nofollow">{t`home.what-they-write-about-us-in-press-see-more-button`}</a>
    </div>
  </div>,
];


  return (
    <div className="looking-for-startups-in-poland-slider-page">
      <div className="three-circles-motive1">
        <StaticImage
          src="../../../images/home/theme-circles/three-circles-pt3.png"
          alt="Three-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <div className="three-circles-motive2">
        <StaticImage
          src="../../../images/home/theme-circles/three-circles-pt4.png"
          alt="Three-circles"
          placeholder="none"
          loading="eager"
        />
      </div>
      <section className="container">
        <div className="looking-for-startups-in-poland-slider-ctn">
          <div className="looking-for-startups-in-poland-slider-titles">
            <div>
              <h3>{t`home.looking-for-startups-in-poland-subtitle`}</h3>
              <h2>{t`home.looking-for-startups-in-poland-title`}</h2>
              <h4>{t`home.looking-for-startups-in-poland-text`}</h4>
            </div>
            <div className="looking-for-startups-in-poland-slider-title-button">
              <WhiteButton
                as={Link}
                to="/incubator">
                {t`home.looking-for-startups-in-poland-button`}
              </WhiteButton>
            </div>
          </div>
          <HomeHorizontalSlider slides={slidesContent} totalSlidesStateProp={17} isSliderAutoplay={true} autoplaySpeed={8000}/>
        </div>
      </section>
    </div>
  );
};

export default LookingForStartupsInPolandSlider;