import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../styles/home-vertical-team-slider.css"
import LinkedinIcon from "../../../views/team/components/slider/svg/linkedinn-icon";

const HomeVerticalTeamSlider = ({ management, isSecondary}) => {
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplaySpeed: 4000,

    rtl: isSecondary,

    responsive: [
      {
        breakpoint: 1131,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 974,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 568,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <div className="home-page">
      <div className="team-slider-wrapper">
        <div className={`team-slider-section ${isSecondary ? "team-slider-section-secondary" : ""}`}>
          <Slider {...settings} ref={sliderRef} className="team-slider">
            {management?.members?.map((member, index) => (
              <section key={member.name} className="team-member-slider">
                <img
                  src={member.photo?.path}
                  alt={`${member.photo?.title} - profile`}
                  className="profile-photo"
                />
                <h3 style={{ color:'#FFFFFF' }}>{member.name}</h3>
                <h4 style={{ color:'#FFFFFF' }}>{member.role}</h4>
                <div className="slider-links-box">
                  {member?.linkedin && (
                    <a
                      className="contact-info"
                      href={member?.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                     <LinkedinIcon isIconBrightBlue={isSecondary}/>
                      <p className="linkedin-link-wrapper">{member?.linkedin.substring(25)}</p>
                    </a>
                  )}
                </div>
              </section>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
    ;
};

export default HomeVerticalTeamSlider;
